/* --------------------------
  Impressum
--------------------------- */

.impressum {
    padding: 0 5%;

    @include breakpoint($md) {
        padding: 0 15px;
    }

    .sidebar-nav-wrapper {
        float: left;
        width: 225px;
        padding-bottom: 100px;
        display: block;

        @include breakpoint($xs-ls) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 0;
            float: none;
        }

        .sidebar-navigation {
            padding-top: 60px;
            padding-left: 0;
            padding-bottom: 100px;

            @include breakpoint($xs-ls) {
                padding: 0 0 15px 0;
                height: 60px;
                background-color: fade-out($color-alto, 0.1);
                overflow: hidden;

                &.is-open {
                    height: auto;
                }
            }

            &.affix-top {
                position: static;
                top: 0;
            }

            &.affix {
                position: fixed;
                top: 0;
                width: 225px;

                @include breakpoint($xs-ls) {
                    width: 100%;
                    background-color: fade-out($color-alto, 0.1);
                }
            }

            .first-level {
                text-align: right;
                list-style-type: none;

                @include breakpoint($xs-ls) {
                    text-align: left;
                }

                .sidemenu-btn {
                    height: 60px;
                    width: 100%;
                    border: none;
                    background: transparent;
                    display: block;
                    padding: 20px 15px;
                    text-align: left;
                }

                a {
                    display: block;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    text-overflow: ellipsis;
                    font-weight: 600;
                    font-size: 14px;
                    color: $color-cod-gray;
                    padding: 10px 20px 10px 10px;

                    &:hover,
                    &:active,
                    &:focus {
                        color: $color-cod-gray;
                        text-decoration: none;
                        background-color: $color-gallery;
                    }
                }
            }

            .sub-navigation {
                padding: 0;

                .second-level {
                    text-align: right;
                    list-style-type: none;

                    @include breakpoint($xs-ls) {
                        text-align: left;
                    }

                    a {
                        display: block;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        text-overflow: ellipsis;
                        font-weight: 400;
                        font-size: 12px;
                        color: $color-cod-gray;
                        padding: 8px 20px 8px 8px;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $color-cod-gray;
                            text-decoration: none;
                            background-color: fade-out($color-gallery, 0.3);
                        }
                    }
                }
            }
        }
    }

    .sidebar-content-wrapper {
        float: left;
        width: 690px;
        padding-top: 20px;
        border-left: 1px solid $color-alto;
        padding-bottom: 100px;
        padding-left: 100px;
        overflow: hidden;
        display: block;

        @include breakpoint($md) {
            padding-left: 60px;
            width: 715px;
        }

        @include breakpoint($sm) {
            padding-left: 40px;
            width: 495px;
        }

        @include breakpoint($xs-ls) {
            padding: 60px 0 0 0;
            width: 100%;
            border: none;
        }

        .section-block {
            padding: 70px 0;
            border-bottom: 2px dotted $color-alto;
            color: $color-mine-shaft;

            &:last-child {
                border: none;
            }

            h2 {
                margin: 0;
                text-transform: uppercase;
                font-weight: 800;
                letter-spacing: 2px;
                color: $color-cod-gray;
            }

            .contentblock {
                padding-top: 70px;

                @include breakpoint($xs-ls) {
                    padding-top: 30px;
                }

                h4 {
                    margin: 0 0 15px 0;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    color: $color-cod-gray;
                }

                b, strong {
                    font-weight: 800;
                }

                p {
                    line-height: 1.75;
                }

                ul {
                    padding-left: 0px;
                    padding-bottom: 20px;
                    list-style-type: none;

                    li{
                        position: relative;
                        padding-left: 15px;

                        &::before{
                            content: '‒';
                            position: absolute;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
