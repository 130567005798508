/*#####################################################################################################################
© xMaps - Manuel Kleinert - v 0.0.3 - 25.004.2016
#####################################################################################################################*/

.maps-brick {
    min-height:500px;
    width:100%;
}

/* Ajax Loader */
.maps-brick-loader {
    display: none;
    position: absolute;
    top: 50%;
	margin-top: -56px;
	width: 100%;
	height: 50px;
	z-index: 1000;
	text-align: center;
}

.maps-brick-loader .loader-panel {
	background-color: rgba(0,0,0,0.85);
	display: inline-block;
	color: #fff;
	padding: 15px 30px 15px 25px;

	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	border-radius: 8px;
}

.loader-panel .loading-tube {
    display: inline-block;
	width: 50px;
	height: 50px;
	margin-right: 15px;
	border: 4px solid;
	border-radius: 50%;
	border-color: rgb(231, 76, 60);
	animation: cssload-scale 1000ms infinite linear;
    -o-animation: cssload-scale 1000ms infinite linear;
    -ms-animation: cssload-scale 1000ms infinite linear;
    -moz-animation: cssload-scale 1000ms infinite linear;
    -webkit-animation: cssload-scale 1000ms infinite linear;
}

.loader-panel span {
	font-weight: 800;
	text-transform: uppercase;
	white-space: normal;
	font-size: 12px;
	position: relative;
	top: -17px;
}


.maps-brick-loader .loader-error {
	background-color: rgba(0,0,0,0.85);
	display: inline-block;
	color: #fff;
	padding: 15px 30px 15px 25px;

	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	border-radius: 8px;
}

.maps-brick-loader .loader-error i {
    display: inline-block;
	width: 50px;
	height: 50px;
	margin-right: 15px;
	color: rgb(231, 76, 60);
    font-size: 40px;
    line-height: 52px;
    text-align: center;
}

.loader-error span {
	font-weight: 800;
	text-transform: uppercase;
	white-space: normal;
	font-size: 12px;
	position: relative;
	top: -6px;
}

.maps-brick-loader .loader-success {
	background-color: rgba(0,0,0,0.85);
	display: inline-block;
	color: #fff;
	padding: 15px 30px 15px 25px;

	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	border-radius: 8px;
}

.maps-brick-loader .loader-success i {
    display: inline-block;
	width: 50px;
	height: 50px;
	margin-right: 15px;
	color: rgb(46, 204, 113);
    font-size: 40px;
    line-height: 52px;
    text-align: center;
}

.loader-success span {
	font-weight: 800;
	text-transform: uppercase;
	white-space: normal;
	font-size: 12px;
	position: relative;
	top: -6px;
}

@keyframes cssload-scale {
	0% { transform: scale(0); transform: scale(0); }
	90% { transform: scale(0.7); transform: scale(0.7); }
	100% { transform: scale(1); transform: scale(1); }
}

@-o-keyframes cssload-scale {
	0% { -o-transform: scale(0); transform: scale(0); }
	90% { -o-transform: scale(0.7); transform: scale(0.7); }
	100% { -o-transform: scale(1); transform: scale(1); }
}

@-ms-keyframes cssload-scale {
	0% { -ms-transform: scale(0); transform: scale(0); }
	90% { -ms-transform: scale(0.7); transform: scale(0.7); }
	100% { -ms-transform: scale(1); transform: scale(1); }
}

@-moz-keyframes cssload-scale {
	0% { -moz-transform: scale(0); transform: scale(0); }
	90% { -moz-transform: scale(0.7); transform: scale(0.7); }
	100% { -moz-transform: scale(1); transform: scale(1); }
}

@-webkit-keyframes cssload-scale {
	0% { -webkit-transform: scale(0); transform: scale(0); }
	90% { -webkit-transform: scale(0.7); transform: scale(0.7); }
	100% { -webkit-transform: scale(1); transform: scale(1); }
}
