.main-footer {
    background-color: $color-argusblue;
    width: 100%;
    height: 60px;
    line-height: 60px;
    position: relative;

    a {
        color: $color-white;
        text-decoration: none;
    }
}

.impressum {
    padding: 0;
}
#argusauge{
    display: block;
    float: right;
    width: 53px;
    height: 60px;
    text-indent: -9999px;
    background: url('../../setup/img/icons/eyewhite_1.png') center center no-repeat;
    background-size: 53px auto;
}
