/*
 * Colors
 * @resources
 * http://razorjam.github.io/sasscolourfunctioncalculator/                      From To Color Conversion
 * http://chir.ag/projects/name-that-color/                                     Name That Color!
 * http://www.sassmeister.com/                                                  SCSSS To CSS Conversion
 * -----------------------------------------------------------------------------
 */
$color-argusblue:            #308dc3 !default;
$color-argusblack:           #313131 !default;
$color-argusgrey:            #666666 !default;
$color-arguslightgrey:       #eeeeee !default;
$color-argusgreybackground:  #fafafa !default;



$color-endeavour:  #0663ab !default;                                            // CI
$color-dawn:       #a29f9d !default;

$color-black:          #000000;                                                 // Shades of black and white
$color-cod-gray:       lighten($color-black, 6.5%) !default;  // #111111
$color-mine-shaft:     lighten($color-black, 13.5%) !default; // #222222
$color-tundora:        lighten($color-black, 20%) !default;   // #333333
$color-emperor:        lighten($color-black, 33.5%) !default; // #555555
$color-boulder:        lighten($color-black, 46.7%) !default; // #777777
$color-dusty-gray:     lighten($color-black, 60%) !default;   // #999999
$color-silver-chalice: lighten($color-black, 66.7%) !default; // #aaaaaa
$color-silver:         lighten($color-black, 72.9%) !default; // #bababa
$color-chrome:         lighten($color-black, 80%) !default;   // #cccccc
$color-alto:           lighten($color-black, 87.8%) !default; // #e0e0e0
$color-gallery:        lighten($color-black, 93.5%) !default; // #eeeeee
$color-white:          #ffffff;

$color-success:       #2ecc71 !default;                                         // Success, warning and error
$color-success-dark:  darken(saturate(adjust-hue($color-success, -0.1097), 0.1803), 7.2549) !default;
$color-warning:       #f39c12 !default;
$color-warning-dark:  lighten(desaturate(adjust-hue($color-warning, -8.6367), 10.6866), 0.5882) !default;
$color-error:         #e74c3c !default;
$color-error-dark:    darken(desaturate(adjust-hue($color-error, 0.0235), 14.6779), 10.9804) !default;

/*
 * Breakpoints
 * http://breakpoint-sass.com/                                                  Documentation
 * -----------------------------------------------------------------------------
 */
$md: 1199px !default;                                                           // default breakpoints
$sm: 991px !default;
$xs-ls: 767px !default;
$xs-pt: 480px !default;

$min-lg: min-width 1200px !default;                                             // min-width breakpoints
$min-md: min-width 992px !default;
$min-sm: min-width 768px !default;
$min-xs-ls: min-width 481px !default;

$hidpi: min-resolution 1.5dppx !default;                                        // special breakpoints
$cross-reso: max-resolution 143dpi !default;
