@import 'bourbon';
@import 'modules/core';
@import 'modules/animate';
@import 'modules/autoheight';
@import 'modules/lazyload';
@import 'modules/video';

// Themes
@import 'themes/theme.default';

// OWL Top Nav
// =============================
.owl-carousel{
    .owl-nav-top{
        position: absolute;
        top: 50%;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        @include transition(opacity .2s ease-in 0s);

        div{
            width: 50px;
            height: 50px;
            margin-top: -25px;
            background: rgba(0,0,0,0.5);
            color: #fff;
            text-align: center;
            padding: 4px;
            font-size: 30px;
            cursor: pointer;
            position: absolute;

            &.owl-next{
                right: 0;
            }

            &.owl-prev{
                left: 0;
            }
        }
    }

    &:hover{
        .owl-nav-top{
            opacity: 1;
            pointer-events: auto;
        }
    }
}


// View:: images-clips
// =============================
.owl-carousel{
    .images-clips{
        position: relative;

        &.item-video{
            min-width: 200px;
        }

        .text{
            position: absolute;
            bottom: 0;
            left: 0;
            min-width: 100%;
            color: #fff;
            background-color: rgba(0,0,0,0.5);
            padding: 10px;

            p{
                margin: 0;
            }
        }
    }
}


// View:: images-folder
// =============================
.owl-carousel{
    .images-folder{
        position: relative;

        .text{
            position: absolute;
            bottom: 0;
            left: 0;
            min-width: 100%;
            color: #fff;
            background-color: rgba(0,0,0,0.5);
            padding: 10px;

            p{
                margin: 0;
            }
        }
    }
}
