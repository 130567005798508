/* Browserweiche
-------------------------------------------------- */

#vcenter{
    position: absolute;
    top: 50%;
    height: 500px;
    margin-top: -250px;
    width: 100%;
    background-color: #fff;
}

.links a:hover,
.rechts a:hover{
    text-decoration: none !important;
    background-color: transparent;
    border-bottom: none !important;
}

img{
    border:none;
}

#content{
    width: 800px;
    height: 500px;
    margin: 0 auto;
    padding: 0px;
    background-color: transparent;
    position: relative;
    font-size: 14px;
}

#left{
    position: absolute;
    width: 400px;
    left: 0px;
    top: 140px;
    background-color: transparent;
    float: left;
}

#right{
    position: absolute;
    width: 400px;
    left: 400px;
    top: 140px;
    background-color: transparent;
    float: left;
}

.weicheBg{
    width: 100%;
    background-color: transparent;
}

.error{
    padding-bottom: 30px;
}
