// FONTS
@font-face {
    font-family: 'simplonnormlight';
    src: url('../../src/fonts/simplonnormlight.eot');
    src: url('../../src/fonts/simplonnormlight.eot') format('embedded-opentype'),
         url('../../src/fonts/simplonnormlight.woff2') format('woff2'),
         url('../../src/fonts/simplonnormlight.woff') format('woff'),
         url('../../src/fonts/simplonnormlight.ttf') format('truetype'),
         url('../../src/fonts/simplonnormlight.svg#simplonnormlight') format('svg');
}

@font-face {
    font-family: 'simplonnormregular';
    src: url('../../src/fonts/simplonnormregular.eot');
    src: url('../../src/fonts/simplonnormregular.eot') format('embedded-opentype'),
         url('../../src/fonts/simplonnormregular.woff2') format('woff2'),
         url('../../src/fonts/simplonnormregular.woff') format('woff'),
         url('../../src/fonts/simplonnormregular.ttf') format('truetype'),
         url('../../src/fonts/simplonnormregular.svg#simplonnormregular') format('svg');
}

@font-face {
    font-family: 'simplonnormmedium';
    src: url('../../src/fonts/simplonnormmedium.eot');
    src: url('../../src/fonts/simplonnormmedium.eot') format('embedded-opentype'),
         url('../../src/fonts/simplonnormmedium.woff2') format('woff2'),
         url('../../src/fonts/simplonnormmedium.woff') format('woff'),
         url('../../src/fonts/simplonnormmedium.ttf') format('truetype'),
         url('../../src/fonts/simplonnormmedium.svg#simplonnormmedium') format('svg');
}

// Hide Outlines
a:active,
a:focus {
    outline: none;
}

// Hide Mobile Navigation
#mobnav:not(.mm-menu) {
    display: none;
}

// Selection
*::selection {}

/* --------------------------
  Feedback Panel
--------------------------- */

.feedback {
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 30px 30px 20px 30px;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    border: none;
    opacity: 1;
    z-index: 5000;
    border-radius: 0;
    @include transition(opacity 0.2s ease-in-out);

    &:hover {
        opacity: 1;
    }

    &.alert-success {
        background-color: $color-success;
        border-bottom: 4px solid $color-success-dark;
        color: $color-white;

        i {
            color: $color-success-dark;
        }
    }

    &.alert-warning {
        background-color: $color-warning;
        border-bottom: 4px solid $color-warning-dark;
        color: $color-white;

        i {
            color: $color-warning-dark;
        }
    }

    &.alert-danger {
        background-color: $color-error;
        border-bottom: 4px solid $color-error-dark;
        color: $color-white;

        i {
            color: $color-error-dark;
        }
    }

    i {
        font-size: 2em;
        margin-right: 20px;
        height: 35px;
    }

    span {
        vertical-align: super;

        strong {
            text-transform: uppercase;
        }
    }

    .feedback-closer {
        opacity: 0.75;
        height: 43px;
        width: 43px;
        background: transparent;
        float: right;
        margin-top: 0;
        padding: 0;
        position: relative;
        cursor: pointer;
        @include transition(opacity 0.2s ease-in-out);

        &:hover,
        &:active,
        &:focus {
            opacity: 1;
        }

        &::before,
        &::after {
            position: absolute;
            top: 21px;
            right: 1px;
            display: block;
            content: '';
            width: 35px;
            height: 2px;
            background-color: $color-white;
            @include transform(rotate(45deg));
        }

        &::after {
            @include transform(rotate(-45deg));
        }
    }
}




/* --------------------------
  Main
--------------------------- */

h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
}

h1 {
    line-height: 1.2;
    text-transform: none;
}

h2 {
    font-size: 35px;
    font-family: 'simplonnormlight',sans-serif;
    padding: 0 0 30px 0;
    line-height: 1;
    text-transform: none;
    text-align: center;
    color: $color-argusblue;

    @media (max-width: 1024px) {
        font-size: 30px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
        padding: 10px 0 20px 0;
    }
}

h3 {
    line-height: 1.2;
    text-transform: none;
}

h4 {
    font-weight: normal;
    font-style: normal;
    line-height: 1.5;
}

html, body {
    font-size: 16px;
    font-family: 'simplonnormregular',sans-serif;
    font-weight: normal;
    font-style: normal;
    height: 100%;
    background-color: #fff;
    line-height: 1.6;

    @media (max-width: 767px){
        font-size: 12px;
    }
}

html,button,input,textarea {
    -webkit-font-smoothing:antialiased;
}

a:focus,button:focus {
    outline:0;
}

a,a:hover,a:visited,a:focus {
    text-decoration:none;
}

body {
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

p {
    padding: 0 0 20px 0;
    margin: 0;
}

strong, b {
    font-family: 'simplonnormmedium',sans-serif;
    font-weight: normal;
    font-style: normal;
}

.blue-box, .kontaktbox{
    border: 1px solid $color-argusblue;
    padding: 10px 15px;
    color: $color-argusblue;
    margin: 0 0 20px 0;
    p {
        padding: 0px;
    }
    a {
        text-decoration: none;
        color: $color-argusblue;
    }
}

.main-content{
    width: 100%;
    height: auto;
    overflow: hidden;
    background: #fff;
    padding-top: 100px;
    @media (max-width: 767px) {
        padding-top: 50px;
    }
}

.container {
    @media (min-width: 1200px) {
        width: 1130px;
    }
}

.pimcore_areablock_toolbar {
    top: 150px !important;
}

/* --------------------------
  Slider
--------------------------- */
.intro-screen {
    color: white;
    padding: 100px 0;
    text-align: center;
    background-position: center center;
    background-size: cover;
    background-color: $color-argusblue;
    h1 {
        font-size: 35px;
        font-family: 'simplonnormlight',sans-serif;
        color: #fff;
        padding: 0 0 10px 0;
        margin: 0;
        text-align: center;
        @media (max-width: 991px) {
            font-size: 28px;
        }
        @media (max-width: 767px) {
            padding-top: 20px;
            font-size: 22px;
        }
    }

    p {
        color: #fff;
        text-align: center;
    }

    .contact {
        padding-top: 200px;
        a {
            color: #fff;
            text-decoration: none;
        }
        h3 {
            font-size: 15px;
            padding: 0;
            margin: 0;
            font-family: 'simplonnormmedium',sans-serif;
            color: #fff;
            text-align: left;

            @media (max-width: 1024px) {
                font-size: 13px;
            }
        }

        p {
            font-size: 15px;
            padding: 0 0 10px 0;
            text-align: left;

            @media (max-width: 1024px) {
                font-size: 14px;
            }

            @media (max-width: 991px){
                font-size: 13px;
            }

            @media (max-width: 767px){
                font-size: 12px;
                padding: 0 0 20px 0;
            }
        }

        @media (max-width: 991px) {
            padding-top: 150px;
        }

        @media (max-width: 767px){
            padding-top: 0;
        }
    }

    @media (max-width: 1024px) {
        padding: 75px 0;
    }

    @media (max-width: 767px) {
        padding: 20px 0;
    }
}

.intro-content{
    padding-top: 30px;
    position:relative;;
    p {
        text-align: left;
    }

}


.leistungen-links {
    text-align: left;
    a {
        text-align: left;
        color: #FFF;
    }
}

/* -----------------------------
Loader
------------------------------ */
#preloader {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: #fff url(../../setup/img/icons/eyeloader.png) no-repeat center center;
    z-index:200;
    background-size:53px auto;
}

/* PACE */
.pace {
    -webkit-pointer-events:none;
    pointer-events:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    user-select:none;
}
.pace-inactive {
    display:none;
}

.pace .pace-progress {
    background:#308dc3;
    position:fixed;
    z-index:2000;
    bottom:0;
    right:100%;
    width:100%;
    height:10px;
}


/* --------------------------
Kontakt
----------------------------- */
#map {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    position: relative;

    @media (max-width: 1024px) {
        height: 400px;
    }
    @media (max-width: 991px){
        height: 400px;
    }
    @media (max-width: 767px){
        height: 400px;
    }
}


/* --------------------------
Aktuelles
----------------------------- */

.aktuell{
    text-align: left;
}

p.your-team {
    font-size: 1em;
    margin: 0;
    padding: 0;
    color: $color-argusgrey;
}

p.news-date{
    font-size: 1em;
    margin: 0;
    padding: 0;
    color: $color-argusblack;
}

h3.news-title {
    font-size: 1.6em;
    padding: 0 0 20px 0;
    font-family: 'simplonnormmedium',sans-serif;
    text-align: left;
    color: $color-argusblue;
    margin:0;

    @media (max-width: 767px) {
        font-size: 1.2em;
        padding: 0 0 10px 0;
    }
}

.news-row {
    padding-bottom: 40px;

    @media (max-width: 767px) {
            padding-bottom: 20px;
    }
}

.bodytext {
    font-size: 1em;
    margin: 0;
    padding: 0 0 10px 0;
    color: $color-argusblack;

    @media (max-width: 767px) {
        padding: 0 0 5px 0;
    }
}

.news-image{
    width: 100%;
    height: auto;
    padding-bottom: 15px;

    @media (max-width: 767px) {
        padding-bottom: 10px;
    }

    img {
        width: 100%;
        height: auto;
        max-width: 450px;
    }
}

/* --------------------------
Uns
----------------------------- */
.grid-galerie {
    background: $color-argusgreybackground;
    text-align: center;
    margin: 0 auto;

    h1 {
       font-size: 35px;
       padding: 0 0 30px 0;
       line-height: 1;
       text-transform: none;
       color: $color-argusblue;
   }

   @media (max-width: 767px){
        max-width: 290px;
   }
}

.team-circular {
    width: 266px;
    height: 266px;
    border-radius: 133px;
    -webkit-border-radius: 133px;
    -moz-border-radius: 133px;
    overflow:hidden;                                                            /* Für Image-Element Variante */
}

.grid-row {
    margin-left: -8px;
    margin-right: -8px;
}

.grid-col {
    width: 33.3333%;
    float: left;
    padding-left: 8px;
    padding-right: 8px;

    @media (max-width: 767px){
        width: 100%;
        float: none;
    }
}

.gridimage {
    width: 100%;
    height: auto;
    padding-bottom: 16px;
}

.team-member {
    width: 100%;
    height: auto;
    text-align: center;
    padding: 10px 0 50px 0;

    h3 {
        font-size: 1em;
        padding: 0;
        line-height: 1.2;
        margin: 0;
        font-family: 'simplonnormmedium',sans-serif;
        color: $color-argusblue;
    }

    h4 {
        font-size: 1em;
        padding: 0 0 15px 0;
        margin: 0;
        font-family: 'simplonnormregular',sans-serif;
        color:$color-argusgrey;
    }

    @media (max-width: 1024px){
        padding: 10px 0 40px 0;
    }
}

.team-image {
    padding: 0 0 30px 0;
    text-align: center;

    img{
        width: 100%;
        max-width: 266px;
        height: auto;

    }
    @media (max-width: 1024px) {
        padding: 0 0 20px 0;
    }
}

.white {
    padding: 100px 0;
    background: $color-white;
    color: $color-argusblack;

    @media (max-width: 1024px){
        padding: 75px 0;
    }

    @media (max-width: 767px) {
        padding: 20px 0;
    }
}

.grey {
    padding: 100px 0;
    background: $color-argusgreybackground;
    color: $color-argusblack;

    @media (max-width: 1024px){
        padding: 75px 0;
    }

    @media (max-width: 767px) {
        padding: 20px 0;
    }
}
/* --------------------------
leistungen
----------------------------- */

#augenheilkunde{
    img {
        padding-top: 0px !important;
    }
}
.leistungen-links {
    padding-top: 200px;
    color: $color-white;

    p {
        text-align: left;
        padding-bottom: 0px;
        a {
            display: block;
            font-size: 1em;
            padding-bottom: 40px;
            text-decoration: none;
            background: url(../../setup/img/icons/down.png) left bottom no-repeat;
            background-size: auto 9px;
            -webkit-transition: color .5s;
            -moz-transition: color .5s;
            transition: color .5s;
        }
    }

    @media (max-width: 991px) {
        padding-top: 150px;
    }

    @media (max-width: 767px) {
        display: none;
    }
}

.services {
    border-top: 1px solid #a0a0a0;
}

.service {
    padding: 20px 0 5px 0;
    border-bottom: 1px solid #a0a0a0;
}

.service-title{
    padding-right: 25px;
    background: url(../../setup/img/icons/drop.png) right top no-repeat;
    background-size: 17px auto;
    cursor: pointer;
    h3 {
        font-size: 1em;
        padding: 0;
        font-family: 'simplonnormmedium',sans-serif;
        color: $color-argusblack;
    }

    h4 {
        font-size: 1em;
        padding: 0 0 15px 0;
        font-family: 'simplonnormregular',sans-serif;
        color: $color-argusgrey;
    }
}

.service-title.active {
    background-image: url(../../setup/img/icons/dropup.png) !important;
}

.service-content {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.service-content.show{
    opacity: 1;
    max-height: 20000px;
}

.services-content {
    .responsive-img {
        padding-bottom: 40px;

        @media (max-width: 767px) {
            padding-bottom: 15px;
        }

        @media (min-width: 768px) {
            padding-top: 20px;
        }

    }
}

.edit-title {
    font-weight: bold;
}

.edit-box {
    padding: 10px;
    border:1px solid black ;
}

 /* --------------------------
   Home
 --------------------------- */

.responsive-img {
    width:100%;
    height:auto;
}

#aufEinenBlick {
    background: $color-white;
    color: $color-argusblack;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;

    h2 {
        color: $color-argusblue;
        padding: 0 0 10px 0;

        @media (max-width: 991px){
            font-size: 28px;
        }
        @media (max-width: 767px){
            font-size: 22px !important;
            padding: 0 0 10px 0;
        }
    }

    p {
        padding: 0 0 50px 0;

        @media (max-width: 1024px){
            padding: 0 0 40px 0;
        }

        @media (max-width: 767px){
            padding: 0 0 20px 0;
        }
    }
    @media (max-width: 1024px){
        padding-top: 75px;
        padding-bottom: 75px;
    }

    @media (max-width: 767px){
        margin-top: -50px;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
}

 #angebot-link, .angebot-link
 {
     text-decoration: none;
     display: block;
     width: 100%;
     padding: 15px;
     margin: 50px 0 0 0;
     border: 1px solid $color-argusblue;
     color: $color-argusblue;
     background: url(../../setup/img/icons/angebot.png) right center no-repeat;
     background-size: 45px auto;
     text-align: center;
 }

 #standorte {
     background: $color-argusgreybackground;
     color: $color-argusblack;
     padding-top: 50px;
     padding-bottom: 85px;
     h3 {
         font-size: 15px;
         padding: 0;
         margin: 0;
         font-family: 'simplonnormmedium',sans-serif;
         color: $color-argusblack;
         line-height: 1.2;
         text-transform: none;
         @media (max-width: 1024px){
            font-size: 13px;
         }
         @media (max-width: 767px){
            font-size: 13px;
         }
     }

    a{
        text-decoration: none;
        color: $color-argusblack;
        &:hover {
            color: $color-argusblue;
        }
    }
    @media (max-width: 1024px){
        padding-top: 40px;
        padding-bottom: 60px;
    }
    @media (max-width: 767px){
        padding-top: 20px;
        padding-bottom: 10px;
    }

 }

#standorte p {
    font-size: 15px;
    padding: 0 0 10px 0;
    @media (max-width: 991px){
        font-size: 13px;
    }
    @media (max-width: 767px) {
        font-size: 12px;
        padding: 0 0 10px 0;
    }
}

/* --------------------------
Media
----------------------------- */

@media (max-width: 767px) {
    h2 {
        font-size: 16px;
        padding: 10px 0 20px 0;
    }

    #nav {
        display: none;
    }
    .team-member h3 {
        font-size: 1em;
        padding: 0;
        font-family: 'simplonnormmedium',sans-serif;
        text-align: center;
        color: $color-argusblue;
    }

    .team-member h4 {
        font-size: 1em;
        padding: 0 0 15px 0;
        font-family: 'simplonnormregular',sans-serif;
        color: $color-argusgrey;
    }
}

/* ---------------------------
Page-Intro
--------------------------- */

#hero {
    display: none;
    width: 100%;
    min-height: 500px;
    background-color: #fff;
    background-position: center bottom;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -ms-behavior: url(/backgroundsize.min.htc);
    position: relative;
    //background-image: url('/static/setup/img/intro/herointro.jpg');
    @media (max-width: 767px) {
        min-height: 250px;
        height: 250px !important;
        overflow: hidden;
        margin-top: 50px;
    }
}

#introbox{
    @media (max-width: 767px) {
        padding: 15px;
        height: auto;
        h1 {
            font-size: 13px;
        }
        p {
            font-size: 12px;
        }
    }
}

.page-intro-container {
    position: absolute;
    width: 100%;
    height: 425px;
    max-width: 660px;
    padding: 0 15px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.page-intro-logo {
    width: 100%;
    height: 100px;
    background: url(../../setup/img/logos/argusstart.png) center top no-repeat;
    background-size: 245px auto;
    text-indent: -9999px;

    @media (max-width: 767px) {
        background: none !important;
        padding-bottom: 10px;
        height: auto;
        font-family: 'simplonnormmedium',sans-serif;
        text-align: center;
        padding-bottom: 10px;
        color: $color-argusblack;
        text-indent: 0px;
    }
}

.page-intro-content {
    h1 {
        font-size: 18px;
        font-family: 'simplonnormlight',sans-serif;
        color: $color-argusblack;
        padding: 0;
        margin: 0;
        line-height: 1.6;
        text-align: center;
    }
    p {
        font-size: 18px;
        font-family: 'simplonnormlight',sans-serif;
        color: #666666;
        padding: 0 0 30px 0;
        margin: 0 auto;
        line-height: 1.6;
        text-align: center;
        max-width: 550px;
        @media (max-width: 767px) {
            padding: 0 0 10px 0;
        }
    }
}

.page-intro-special-content {
    transition: color .5s;

    p {
        padding: 0px;
    }
}

.page-intro-link  {
    display: block;
    width: 100%;
    padding: 15px;
    margin: 0 0 30px 0;
    border: 1px solid $color-argusblue;
    background: #f7f8f9;
    text-decoration: none;

    a, p {
        color: $color-argusblue;
        text-align: center;
    }

    &:hover{
             text-decoration: none;
     }
}

.go-down-box{
    @media (max-width: 767px) {
        display: none !important;
    }
}

.go-down {
    display: block;
    width: 44px;
    height: 44px;
    text-indent: -9999px;
    margin: 0 auto;
    background: url(../../setup/img/icons/downarrow.png) center center no-repeat;
    cursor: pointer;
}


/* -------------------------
Formular
-------------------------- */

.control-label, .has-succes {
    @media (min-width: 768px) {
        text-align: left !important;
        font-size: 1em;
        line-height: 40px;
        padding: 0 !important;
        margin: 0;
        font-family: 'simplonnormmedium',sans-serif;
        font-weight: normal;
        color: $color-argusblack !important;
    }
}

.form-group {
    margin: 0px !important;
}

.form-control{
    border-radius: 0 !important;
    width: 100%;
    line-height: 40px;
    padding: 0 10px;
    margin: 0 0 10px 0;
    color: $color-argusblack;
    background: #eee;
    border: none;
    font-size: 1em;
    font-family: 'simplonnormregular',sans-serif;
}


#contact {
    padding: 0 0 100px 0;

    textarea {
        width: 100%;
        height: 200px;
        line-height: 1.4;
        padding: 10px;
        margin: 0 0 15px 0;
        color: $color-argusblack;
        background: #eee;
        border: none;
        font-size: 1em;
        font-family: 'simplonnormregular',sans-serif;
        resize: none;
    }
    @media (max-width: 767px){
        padding: 0 0 20px 0;
    }
}

#submitButton {
    float: right;
    margin: 0;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
    font-weight: normal;
    font-size: 1em;
    font-family: 'simplonnormregular',sans-serif;
    border: 1px solid $color-argusblue;
    color: $color-argusblue;
    background: #fff;
    -webkit-transition: background .5s;
    -moz-transition: background .5s;
    transition: background .5s;
    border-radius: 0 !important;

    &:hover{
        background: $color-argusblue;
        color: #fff;
        -webkit-transition: background .5s;
        -moz-transition: background .5s;
        transition: background .5s;
    }
}
#receiver {
    width: 100%;
    background-color: #fff;
    color: $color-argusblue;
    text-align: left;
    font-weight: normal;
    padding: 0 0 0 10px;
    height: 40px;
    line-height: 40px;
    border: 1px solid $color-argusblue;
    overflow: hidden;
    position: relative;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;

    option {
        font-size: 1em !important;
        padding: 10px !important;
        margin: 0;
        background-color: #fff;
        min-width: 100%;
        border-left: 1px solid $color-argusblue;
        border-right: 1px solid $color-argusblue;
        outline: none !important;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
    }
}

.maps-brick {
    width: 100%;
    height: 600px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
}
