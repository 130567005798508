
#home{
    display: block;
    float: left;
    height: 100px;
    width: 174px;
    text-indent: -9999px;
    background: url(../../setup/img/logos/argus.png) center center no-repeat;
    background-size: 174px auto;
}

#mainNav {
    z-index: 300;
    position: fixed;
    width: 100%;
    height: 101px;
    border-bottom: 1px solid #fafafa;
    background-color: #fff;
    top: 0;
    left: 0;
    @media (max-width: 767px){
        display: none;
    }
}

.main-nav{
    width: auto;
    height: 100px;
    padding: 0;
    margin: 0;
    float: right;
    list-style-type: none;

    li {
        float:left;
        height: 100px;
        line-height: 100px;
        padding:0 0 0 30px;
        margin: 0;
    }


    a {
        text-transform: uppercase;
        text-decoration: none;
        display: block;
        height: 100px;
        line-height: 100px;
        color: #313131;

        &:hover,  &:active{
            color: #308dc3;
            text-decoration: none;
        }


    }

    .active {
        color: #308dc3;
        text-decoration: none;
    }

}


.navbar-nav li a{
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    height: 100px;
    line-height: 100px;
    color: #313131;
}


/* ------------------------------
Mobile nav
--------------------------------- */

#mobileNav{
    display: none;

    @media (max-width: 767px){
        display: block;
        font-size: 14px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 300;
    }
}


.mobile-nav {
    @media (max-width: 767px) {
        position: relative;
        height: auto;
        max-height: 0;
        padding: 0;
        overflow: hidden;
        -webkit-transition: max-height .5s;
        -moz-transition: max-height .5s;
        transition: max-height .5s;

        ul {
            width: 100%;
            height: auto;
            line-height: 49px;
            text-align: right;
            background: #fff;
            text-transform: uppercase;
            float: none;
                padding-left: 0px;

            li {
                float: none;
                height: none;
                line-height: none;
                display: block;
                margin: 0;
                width: 100%;
                padding: 0 15px;

                a {
                    width: 100%;
                    display: block;
                    background-color: #fff;
                    color: #313131;
                    border-bottom: 1px solid #ccc;
                }
            }
        }
    }
}

.show-mobile-nav {
    @media (max-width: 767px) {
        display: block;
        width: 100%;
        height: 50px;
        background: #fff;
        position: relative;
        z-index: 200;
        border-bottom: 1px solid #308dc3;
    }
}


.mob-nav-open {
    @media (max-width: 767px){
        max-height: 250px;
    }
}

#mobileLogo {
    @media (max-width: 767px){
        display: block;
        width: 150px;
        height: 49px;
        background: #fff url(../../setup/img/logos/argus2x.png) 15px 17px no-repeat;
        background-size: auto 20px;
        text-indent: -9999px;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 305;
    }
}

#hamburger {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    background: url(../../setup/img/icons/hamburger.png) center center no-repeat;
    background-size: 20px auto;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}


.rotate {
    @media (max-width: 767px){
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}

.navbar {
    position: relative !important;
}
